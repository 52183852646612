import React, { useEffect } from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';

import { HeaderBar } from '../../components/header';

import { PageStore } from '../store';
import { PageLogin } from '../user/login';
import { PageSigup } from '../user/sigup';
import { PageUserProfile } from '../user/profile';
import { PageUserAddress } from '../user/address';
import { PageUserAddressCreate } from '../user/address/create';
import { PageUserPayment } from '../user/payment';
import { PageUserAddressUpdate } from '../user/address/update';
import { PageUserPaymentCreate } from '../user/payment/create';
import { PageUserPaymentUpdate } from '../user/payment/update';
import { PageUserRequests } from '../user/request';
import { PageUserRequestView } from '../user/request/view';
import { PageCartList } from '../cartlist';


import RoutePritvate from '../../components/navigation/private';

import '../../assets/style/pages/base/index.scss';

export const PageBase = (props) => {

    useEffect(()=>{

        console.log('BASE');

    }, []);

    return (
        <div className="page-base">
            <header className="area-header">
                <HeaderBar {...props} />
            </header>
            <section>
                <BrowserRouter key={`${props.location.pathname.replace(/\//gi,'_')}`} basename="/">
                    <Switch>
                        <Route exact path="/:client/user/login" component={(p)=><PageLogin {...p} {...props} />} />
                        <Route exact path="/:client/user/sigup" component={(p)=><PageSigup {...p} {...props} />} />
                        <Route exact path="/:client" component={(p)=> <PageStore {...p} {...props} />} />
                        <Route exact path="/:client/user/cart" component={(p)=><PageCartList {...p} {...props} />} />
                        <RoutePritvate exact path="/:client/user/address" component={(p)=> <PageUserAddress {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/address/create" component={(p)=> <PageUserAddressCreate {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/address/update/:addressid" component={(p)=> <PageUserAddressUpdate {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/payment" component={(p)=> <PageUserPayment {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/payment/create" component={(p)=> <PageUserPaymentCreate {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/payment/update/:paymentid" component={(p)=> <PageUserPaymentUpdate {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/requests" component={(p)=> <PageUserRequests {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user/request/view/:requestid" component={(p)=> <PageUserRequestView {...p} {...props} /> } />
                        <RoutePritvate exact path="/:client/user" component={(p)=> <PageUserProfile {...p} {...props} /> } />
                        <Route path="*" component={()=><>Base - {props.match.params.client} - {props.location.pathname}</>} />
                    </Switch>
                </BrowserRouter>
            </section>
        </div>
    )
}