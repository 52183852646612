import React, {useEffect, useRef, useState} from 'react';

import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { useForm, Controller } from 'react-hook-form';

import { ImageLoader } from '../../../components/imageloader';
import { home } from 'react-icons-kit/icomoon/home';

import { useAPP } from '../../../services/provider/app';

import { userLogin, userProfile } from '../../../services/api/user';

import { getCartList } from '../../../services/shoppingcart';

import { login, isAuthenticated } from '../../../services/auth';

import { URL_APLICATION } from '../../../services/api';

import { getStore } from '../../../services/api/store';

import '../../../assets/style/pages/user/login/index.scss';

export const PageLogin = (props) => {

    if(isAuthenticated())
        props.history.push( getCartList().length > 0 ? `/${props.match.params.client}/cart` : `/${props.match.params.client}`);

    const defaultValues = {
        username: '',
        password: ''
    }

    const [isLoad, setIsLoad] = useState(false);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const errors  = form.formState.errors ;
    const handleSubmit = form.handleSubmit;

    const passwordContent = useRef();
    const toast = useRef(null);

    const {company, setCompany, setVersion, setUser} = useAPP();

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const setPassword = (_data) => {

        const temp = passwordContent.current ? passwordContent.current : [];

        temp.push(_data);

        passwordContent.current = temp;

        const textInput = [];
        textInput.length = temp.length;
        textInput.fill(0);

        form.setValue('password', textInput.join(''));

    }

    const removePassword = () => {
        const temp = passwordContent.current ? passwordContent.current : [];

        temp.pop();

        const textInput = [];
        textInput.length = temp.length;
        textInput.fill(0);

        form.setValue('password', textInput.join(''));

    }

    const keyBoard = () => {
        let num = '0123456789';
        num = (num.split('')).sort(item => Math.random() - 0.5 );
        const keyboard = [];
        
        for(let i = 0; i < num.length; i+=2){
            const bt = <>
                <Button onClick={(e)=>{ e.preventDefault(); setPassword(`${num[i]}${num[i + 1]}`);}} className="p-button-sm" style={{width:'100%'}} label={`${num[i]} ou ${num[i + 1]}`} />
            </>
            keyboard.push(bt);
        }

        keyboard.push(<><Button onClick={(e)=>{ e.preventDefault(); removePassword()}} className="p-button-sm" style={{width:'100%'}} icon="pi pi-caret-left" /></>)

        return keyboard.map(item => item);

    }

    const onSubmit = async (_data) => {
        setIsLoad(true);

        _data.criptokey = true;
        _data.password = passwordContent.current.join('');
        
        const data = await userLogin(props.match.params.client, _data);
        
        if(data.data){
            login(data.data);
            const user = await userProfile();
            setUser(user.data);
            setVersion(new Date().getTime());
            
            props.history.push( getCartList().length > 0 ? `/${props.match.params.client}/cart` : `/${props.match.params.client}`);

        }else{
            toast.current.show({severity: 'error', summary: 'Erro', detail: 'Usuário e/ou senha inválido(s).'});
        }


        setIsLoad(false);
    }

    useEffect(()=>{

        const getData = async () => {
            const data = await getStore(props.match.params.client);
            setCompany(data.data);
        }

        if(!company)
            getData();


    }, [company, setCompany, props]);

    return (
        <div className="page-user-login">
            <div className="box">
                <div className="p-grid p-jc-center">
                    <div>
                        <div className="avatar">
                            <ImageLoader image={company?.detalhe?.icone ? `${URL_APLICATION}${company?.detalhe?.icone}`: null} default={home} />
                        </div>
                        <p style={{textAlign:'center',fontSize: '1em', fontWeight: 800}}>
                            {company?.nome}
                        </p>
                    </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="p-grid p-mt-2 p-mb-2">
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="username" control={control} rules={{ required: 'Campo obrigatório', maxLength:11, minLength:11 }} render={({ field, fieldState }) => (
                                        <InputText type="number" style={{width:'100%'}} id={field.name} {...field} autoFocus />
                                    )} />
                                    <label htmlFor="username">CPF *</label>
                                </span>
                                {getFormErrorMessage('username')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-1">
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="password" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                        <Password disabled inputStyle={{width: '100%'}} feedback={false} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="password">Senha *</label>
                                </span>
                                {getFormErrorMessage('password')}
                                <div className="keyboard">
                                    {keyBoard()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-d-flex p-jc-end">
                        <span className="recovery-access">Esqueceu sua senha?</span>
                    </div>
                    <div className="p-d-flex p-jc-center p-m-1">
                        <Button type="submit" loading={isLoad} style={{width: '100%', maxWidth:'300px'}} label="Entrar" />
                    </div>
                </form>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}