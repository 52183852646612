import React, {useEffect, useState, useRef} from 'react';

import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { confirmDialog } from 'primereact/confirmdialog';

import { userPayment, userPaymentDelete } from '../../../services/api/user';

import { formatToCredidCard } from '../../../services/utils';

import '../../../assets/style/pages/user/cards/index.scss';

export const PageUserPayment = (props) => {

    const [addressList, setAddressList] = useState(null);

    const toast = useRef(null);

    const getData = async () => {
        const data = await userPayment();
        console.log(data.data);
        setAddressList(data.data);
    }

    const deletePayment = async (_data) => {

        confirmDialog({
            message: <p>Deseja realmente apagar este item?</p>,
            header: 'Confirmação',
            acceptLabel:'Sim',
            rejectLabel:'Não',
            acceptClassName:'p-button-danger',
            accept: async () => {
                document.querySelector(`#address-item-${_data.id}`).classList.add('delete');
                const deletePaymentRequest = await userPaymentDelete(_data);

                if(deletePaymentRequest.data){
                    toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Endereço deletado com sucesso.'});
                }else{
                    toast.current.show({severity: 'error', summary: 'Erro', detail: 'Endereço não deletado. Tente novamente.'});
                    document.querySelector(`#address-item-${_data.id}`).classList.remove('delete');
                }

                getData();
            },
            reject: () => {

            }
        });

    }

    useEffect(()=>{

        getData();

    }, []);

    return (
        <div className="page-user-cards">
            <div className="main-frame">
                <h3>
                    Formas de pagamento
                </h3>
                <div className="p-d-flex p-mb-4 p-mt-2 p-jc-end">
                    <Button onClick={()=>props.history.push(`/${props.match.params.client}/user/payment/create`)} className="p-button-text" label="Adicionar nova forma de pagamento" />
                </div>
                {
                    addressList ?
                        <>
                            {
                                addressList.length > 0 ?
                                    <div>
                                        {
                                            addressList.map((item, id) => {
                                                return <div key={`address-item-${id}`} id={`address-item-${item.id}`} className="card">
                                                    <div className="p-grid p-ai-center">
                                                        <div style={{textAlign:'center', fontSize:'0.7em'}} className="p-col-1">
                                                            {item.bandeira}
                                                        </div>
                                                        <div className="p-col">
                                                            <div>
                                                                <strong>{item.portador}</strong>
                                                            </div>
                                                            <div className="p-grid p-mt-2" style={{fontSize:'0.8em'}}>
                                                                <div className="p-col-4">
                                                                    <strong>Número</strong>: {formatToCredidCard(item.numerotruncado)}
                                                                </div>
                                                                <div className="p-col-2">
                                                                    <strong>Validade</strong>: {item.val_mes}/{item.val_ano}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-fixed" style={{width:'100px'}}>
                                                            <Button onClick={()=> props.history.push({pathname: `/${props.match.params.client}/user/payment/update/${item.id}`, state:{data: item}})} icon="pi pi-pencil" className="p-button-text p-button" />
                                                            <Button onClick={()=> deletePayment(item)} icon="pi pi-trash" className="p-button-text p-button-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                :   
                                <p>
                                    Não há endereços cadastrados.
                                </p>
                            }
                        </>
                    :
                        <div style={{textAlign: 'center', height:'200px'}}> <ProgressSpinner style={{width: '36px', height: '36px', margin: '10px auto'}} /> </div>
                }
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}