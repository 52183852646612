import React, {useState, useEffect, useCallback} from 'react';

import { Button } from 'primereact/button';

import { ImageLoader } from '../../../../components/imageloader';
import { home } from 'react-icons-kit/icomoon/home';

import pedido from '../../../../assets/image/pedido.png';
import preparo from '../../../../assets/image/preparo.png';
import entrega from '../../../../assets/image/entrega.png';

import { URL_APLICATION } from '../../../../services/api';

import { userRequests, userPayment } from '../../../../services/api/user';


import '../../../../assets/style/pages/user/request/view/index.scss';

export const PageUserRequestView = (props) => {

    const timeUpdate = 1000 *  30;

    
    const [request, setRequest] = useState(props.location.state.data);
    const [paymentType, setPaymentType] = useState();

    console.log(request);

    const getLocalDelivery = () => {
        const address = request.enderecos_data.filter(item => ~~item.id === ~~request.esc_responsaveis_enderecos_id)[0];
        let str_address = '';
        if(address){
            str_address = ` ${address.endereco}, ${address.numero}${address.complemento ? `- ${address.complemento}`: ''} - ${address.bairro} - ${address.cidade}`;
        }else{
            str_address = 'Retirado no local/ Entregue mesa'
        }


        return str_address;
    }

    const getPaymentData = () => {

       const getData = async() => {
            let list = [
                {label:'Dinheiro', value: 5 },
                {label:'Débito', value: 1 },
            ];

            const paymentdata =  await userPayment();

            paymentdata?.data?.map(item=> {
                list.push({label:`Crédito - ${item.portador} - ${item.numerotruncado}- ${item.val_mes}/${item.val_ano}`, value:~~item.id, portador: item.portador});
                return item;
            })

            const data = list.filter(item => ~~item.value === ~~request.forma_pagamento)[0];

            setPaymentType(data?.label || '')
       }

       getData();
    }

    const getStatus = (_data) => {

        const sts_pedido = _data.sts_pedido;
        const sts_producao = _data.sts_producao;
        const sts_entrega = _data.sts_entrega;

        let _status = null;


        if(sts_entrega){
            switch(~~sts_entrega){
                case 0:
                    _status = 'Aguardando entregador';
                break;
                case 1:
                    _status = 'Em rota';
                break;
                case 2:
                    _status = 'Entregue';
                break;
                default:
                    _status = sts_entrega
            }

            if(_status)
                return _status;
        }

        if(sts_producao){
            switch(~~sts_producao){
                case 0:
                    _status = 'Aguardando Restaurante';
                break;
                case 1:
                    _status = 'Em produção';
                break;
                case 2:
                    _status = 'Produção Finalizada';
                break;
                default:
                    _status = sts_producao
            }
            
            if(_status)
                return _status;
        }
        
        if(sts_pedido && ~~sts_pedido === 1){
            _status = 'Pedido Aprovado';
        }else{
            _status = 'Aguardando aprovação';
        }

        if(~~_data.cancelado === 1){
            _status = 'Cancelado';
        }


        return _status;
    }

    const getData = useCallback(()=>{

        console.log('Pedido status');

        const getDataRequest = async () => {
            const data = await userRequests();
            let req = data.data.filter(item => item.unique === request.unique)[0];
            
            setRequest(req);

            setTimeout(()=> getData(), timeUpdate);

        }
        
        getDataRequest();
        

    }, [timeUpdate, request])

    useEffect(()=>{
        
        getData();
        getPaymentData();
        //setTimeout(()=> getData(), timeUpdate);

    }, []);

    return (
        <div className="page-user-request-view">
           <div className="main-frame">
                <div className="p-grid">
                    <div className="p-col-12 p-md-2">
                        <div className="avatar">
                            <ImageLoader image={`${URL_APLICATION}/${request?.empresa_data?.detalhe?.icone}`} default={home} />
                        </div>
                        <Button onClick={()=> props.history.push(`/${props.match.params.client}`) } style={{width: '100%', minWidth:'110px', fontSize:'0.8em'}} label="Ver cardápio" className="p-button-sm p-button-text" />
                    </div>
                    <div className="p-col-12 p-md-10" style={{fontSize:'0.8em'}}>
                        <div><strong style={{fontSize:'1.2em'}}>Pedido</strong>: {request.unique}</div>
                        <h3><strong>{request?.empresa_data?.nome}</strong></h3>
                    </div>
                </div>
                <div className="p-grid p-mt-2 p-mb-2" style={{fontSize:'0.8em'}}>
                    <div className="p-col-12">
                        <strong>Entrega</strong>: {getLocalDelivery()}
                    </div>
                </div>
                <div className="p-grid p-mt-2 p-mb-2" style={{fontSize:'0.8em'}}>
                    <div className="p-col-12">
                        <strong>Forma Pagamento</strong>: {paymentType}
                    </div>
                </div>
                <div className="p-grid p-mt-2 p-mb-4" style={{fontSize:'0.8em'}}>
                    <div className="p-col-12 p-md-6">
                        <strong>Data pedido</strong>:{new Date(request?.emissao).toLocaleDateString()} {new Date(request?.emissao).toLocaleTimeString()}
                    </div>
                    <div className="p-col-12 p-md-6">
                        <div className="status">
                            {getStatus(request)}
                        </div>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="status-steps">
                            <div className={`request ${request.sts_pedido && ~~request.cancelado !== 1 ? 'active' : ''} status-${request.sts_pedido}`} >
                                <img src={pedido} alt='' />
                                <div className="text">
                                    Pedido
                                </div>
                            </div>
                            <div className={`production ${request.sts_producao && ~~request.cancelado !== 1 ? 'active' : ''} status-${request.sts_producao}`}>
                                <img src={preparo} alt='' />
                                <div className="text">
                                    Produção
                                </div>
                            </div>
                            <div className={`delivery ${request.sts_entrega && ~~request.cancelado !== 1 ? 'active' : ''} status-${request.sts_entrega}`}>
                                <img src={entrega} alt='' />
                                <div className="text">
                                    Entrega
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-grid p-m-5">
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <Button style={{width:'100px'}} onClick={()=> props.history.push(`/${props.match.params.client}/user/requests`)} label="Sair" className="p-button-danger p-button-sm" />
                    </div>
                </div>
                
           </div>
        </div>
    )
}