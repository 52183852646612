import React, {useState, useRef} from 'react';

import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';

import { addLocale } from 'primereact/api';

import { useForm, Controller } from 'react-hook-form';

import { userSigup } from '../../../services/api/user';

import { localeData } from '../../../services/utils';

import '../../../assets/style/pages/user/sigup/index.scss';

export const PageSigup = (props) => {

    addLocale('br', localeData.br);

    const defaultValues = {
        nome:'',
        email:'',
        rg:'',
        sexo:'',
        nascimento:'',
        telefone:'',
        celular:'',
        cep:'',
        endereco:'',
        numero:'',
        comple:'',
        bairro:'',
        cidade:'',
        uf:'',
        cpf: '',
        senha: ''
    }

    const [isLoad, setIsLoad] = useState(false);
    const [zipcodeLoad, setZipcodeLoad] = useState(false);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const errors  = form.formState.errors ;
    const handleSubmit = form.handleSubmit;
    const reset = form.reset;

    const toast = useRef(null);

    const getDataAddress = async (_data) => {

        if((_data || '' ).replace(/\D/gi,'').length === 8){

            setZipcodeLoad(true);

            const url_base = `https://viacep.com.br/ws/${_data.replace(/\D/gi,'')}/json/`;

            const header = new Headers();
            header.append('Access-Control-Allow-Origin','*');
            const params = {
                method: 'GET',
                headers: header
            }

            const addressData = await fetch(url_base, params);
            const addressLoad = await addressData.json();

            form.setValue('endereco', addressLoad.logradouro);
            form.setValue('bairro', addressLoad.bairro);
            form.setValue('cidade', addressLoad.localidade);
            form.setValue('uf', addressLoad.uf);

            setZipcodeLoad(false);

        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = async (_data) => {
        setIsLoad(true);

        const data = await userSigup(_data);

        setIsLoad(false);

        if(data.data){
            toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Usuário cadastrado.'});
            reset();
        }else{
            toast.current.show({severity: 'error', summary: 'Erro', detail: 'Usuário não cadastrado. Tente novamente.'});
        }
    }

    return(
        <div className="page-user-sigup">
            <div className="main-frame">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>
                        Cadastro de usuário
                    </h3>
                    
                    <Divider>
                        <i className="pi pi-user p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Dados
                        </strong>
                    </Divider>

                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="nome" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} autoFocus />
                                    )} />
                                    <label htmlFor="nome">Nome *</label>
                                </span>
                                {getFormErrorMessage('nome')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="email" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                        <InputText type="email" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="email">E-mail *</label>
                                </span>
                                {getFormErrorMessage('email')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="rg" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="rg">RG</label>
                                </span>
                                {getFormErrorMessage('rg')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="sexo" control={control}  render={({ field, fieldState }) => (
                                        <Dropdown id={field.name} value={field.value} options={
                                            [{label:'Masculino', value:'M'}, {label:'Feminino', value:'F'}]
                                        } onChange={(e) => field.onChange(e.value)} style={{width:'100%'}} {...field}/>
                                    )} />
                                    <label htmlFor="sexo">Sexo</label>
                                </span>
                                {getFormErrorMessage('sexo')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="nascimento" control={control} render={({ field, fieldState }) => (
                                        <Calendar showIcon monthNavigator yearNavigator yearRange={`1900:${new Date().getFullYear()}`}  locale="br" dateFormat="dd/mm/yy" value={field.value} onChange={(e) => field.onChange(e.value)} style={{width:'100%'}} id={field.name} {...field}></Calendar>
                                    )} />
                                    <label htmlFor="nascimento">Data nascimento</label>
                                </span>
                                {getFormErrorMessage('nascimento')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="telefone" control={control} render={({ field, fieldState }) => (
                                        <InputText type="tel" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="telefone">Telefone</label>
                                </span>
                                {getFormErrorMessage('telefone')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="celular" control={control} render={({ field, fieldState }) => (
                                        <InputText type="tel" style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="celular">Celular</label>
                                </span>
                                {getFormErrorMessage('celular')}
                            </div>
                        </div>
                    </div>

                    <Divider>
                        <i className="pi pi-map-marker p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Endereço
                        </strong>
                    </Divider>

                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="cep" control={control} render={({ field, fieldState }) => (
                                        <>
                                            {zipcodeLoad ? <i className="pi pi-spin pi-spinner" /> : '' }
                                            <InputText onInput={(e) => getDataAddress(e.target.value)} style={{width:'100%'}} id={field.name} {...field} />
                                        </>
                                    )} />
                                    <label htmlFor="cep">Cep</label>
                                </span>
                                {getFormErrorMessage('cep')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="endereco" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="endereco">Endereço</label>
                                </span>
                                {getFormErrorMessage('endereco')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="numero" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="numero">Número</label>
                                </span>
                                {getFormErrorMessage('numero')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="comple" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="comple">Complemento</label>
                                </span>
                                {getFormErrorMessage('comple')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="bairro" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="bairro">Bairro</label>
                                </span>
                                {getFormErrorMessage('barro')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="cidade" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="cidade">Cidade</label>
                                </span>
                                {getFormErrorMessage('cidade')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="uf" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="uf">Estado</label>
                                </span>
                                {getFormErrorMessage('uf')}
                            </div>
                        </div>
                    </div>

                    <Divider>
                        <i className="pi pi-shield p-mr-2" />
                        <strong style={{fontSize:'1em'}}>
                            Acesso
                        </strong>
                    </Divider>

                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="cpf" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="cpf">CPF *</label>
                                </span>
                                {getFormErrorMessage('cpf')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="senha" control={control} rules={{ required: 'Campo obrigatório' }} render={({ field, fieldState }) => (
                                        <Password inputStyle={{width: '100%'}} feedback={false} toggleMask style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="senha">Senha *</label>
                                </span>
                                {getFormErrorMessage('senha')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2 p-mb-2">
                        <div className="p-col-12 p-md-6 p-md-offset-6 area-button">
                            <Button loading={isLoad} type="submit" label="Cadastrar" />
                        </div>
                    </div>

                </form>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}