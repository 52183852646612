import React, {useEffect, useState, useRef, useCallback} from 'react';

import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';

import { userRequests } from '../../../services/api/user';

import { ImageLoader } from '../../../components/imageloader';
import { home } from 'react-icons-kit/icomoon/home';

import { URL_APLICATION } from '../../../services/api';

import '../../../assets/style/pages/user/request/index.scss';

export const PageUserRequests = (props) => {

    const [requestList, setRequestsList] = useState(null);

    const timeUpdate = 1000 * 60 * 60;

    const toast = useRef(null);


    const getStatus = (_data) => {

        const sts_pedido = _data.sts_pedido;
        const sts_producao = _data.sts_producao;
        const sts_entrega = _data.sts_entrega;

        let _status = null;
        let ledStatus = 'on';


        if(sts_entrega){
            switch(~~sts_entrega){
                case 0:
                    _status = 'Aguardando entregador';
                break;
                case 1:
                    _status = 'Em rota';
                break;
                case 2:
                    _status = 'Entregue';
                    ledStatus = 'close';
                break;
                default:
                    _status = sts_entrega
            }

            if(_status)
                return <div className={`status ${ledStatus}`}>{_status}</div>;
        }

        if(sts_producao){
            switch(~~sts_producao){
                case 0:
                    _status = 'Aguardando Restaurante';
                break;
                case 1:
                    _status = 'Em produção';
                break;
                case 2:
                    _status = 'Produção Finalizada';
                break;
                default:
                    _status = sts_producao
            }
            
            if(_status)
                return <div className={`status ${ledStatus}`}>{_status}</div>;
        }
        
        if(sts_pedido && ~~sts_pedido === 1){
            _status = 'Pedido Aprovado';
        }else{
            _status = 'Aguardando aprovação';
            ledStatus = 'off';
        }

        if(~~_data.cancelado === 1){
            _status = 'Cancelado';
            ledStatus = 'cancel';
        }


        return <div className={`status ${ledStatus}`}>{_status}</div>;
    }

    const getData = useCallback(() => {

        const getDataRequest = async () => {
            const data = await userRequests();
            console.log(data.data);
            setRequestsList(data.data);

            setTimeout(()=>{

                getData();

            }, timeUpdate)
        }

        getDataRequest();

    },[timeUpdate])


    useEffect(()=>{

        getData();

    }, [getData]);

    return (
        <div className="page-user-request">
            <div className="main-frame">
                <h3>
                    Pedidos
                </h3>
                {
                    requestList ?
                        <>
                            {
                                requestList.length > 0 ?
                                    <div>
                                        {
                                            requestList.map((item, id) => {
                                                return <div key={`address-item-${id}`} id={`address-item-${item.id}`} className="card">
                                                    <div className="p-grid p-ai-center">
                                                        <div className="p-col-12 p-md-1">
                                                            <div className="avatar">
                                                                <ImageLoader image={`${URL_APLICATION}/${item?.empresa_data?.detalhe?.icone}`} default={home} />
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-md-10">
                                                            <div className="p-grid">
                                                                <div className="p-col-12">
                                                                    <strong>{item?.empresa_data.nome}</strong>
                                                                </div>
                                                                <div style={{fontSize:'0.8em'}} className="p-col-12 p-md-4">
                                                                    <strong>ID</strong>: {item.unique}
                                                                </div>
                                                                <div style={{fontSize:'0.8em'}} className="p-col-12 p-md-4">
                                                                    <strong>Data pedido</strong>:{new Date(item?.emissao).toLocaleDateString()} {new Date(item?.emissao).toLocaleTimeString()}
                                                                </div>
                                                                <div style={{fontSize:'0.8em'}} className="p-col-12 p-md-4">
                                                                    <strong>Status</strong>: {getStatus(item)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="p-col-12 p-md-1" style={{textAlign:'center'}}>
                                                            <Button onClick={()=> props.history.push({pathname: `/${props.match.params.client}/user/request/view/${item.unique}`, state:{data: item}})} style={{margin:'0 auto'}} icon="pi pi-eye" className="p-button-text p-button" />
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                :   
                                <p>
                                    Não há endereços cadastrados.
                                </p>
                            }
                        </>
                    :
                        <div style={{textAlign: 'center', height:'200px'}}> <ProgressSpinner style={{width: '36px', height: '36px', margin: '10px auto'}} /> </div>
                }
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}