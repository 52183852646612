import React, {useState, createContext, useContext, useEffect, useRef} from 'react';

import { pwaManifest, pwaServices } from '../../pwa';

export const  AppContext = createContext();

export const AppProvider = (props) => {

    const [cardList, setCardList] = useState([]);
    const [version, setVersion] = useState(0);
    const [user, setUser] = useState(null);
    const [company, setCompany] = useState(null);
    const [products, setProducts] = useState(null);

    const pwaCreate = useRef();

    useEffect(()=>{

        if(company){
            pwaManifest(company);
            pwaServices(company.id);
        }

    },[company])

    return (
        <AppContext.Provider value={{
            cardList,
            setCardList,
            user,
            setUser,
            company,
            setCompany,
            products,
            setProducts,
            version,
            setVersion
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export const useAPP = () => {
    const context = useContext(AppContext);
    const {cardList, setCardList, user, setUser, company, setCompany, products, setProducts, version, setVersion} = context;
    return {
        cardList,
        setCardList,
        user,
        setUser,
        company,
        setCompany,
        products, 
        setProducts,
        version,
        setVersion
    }
}