const CART_KEY = '@CART_LIST';

const cartSessionCreate = () => {
    const date =  new Date();
    date.setMinutes(date.getMinutes() + 30);
    return date.getTime();
}

const cartValidade = ({expire}) => {
    const date = new Date();
    return date.getTime() <= expire;
}

const decriptCart = (_data) => {
    return JSON.parse(atob(_data));
}

const encriptCart = (_data) => {
    return btoa(JSON.stringify(_data));
}

const saveCart = (_data) => {
    const data = {
        list: _data, 
        expire: cartSessionCreate()
    }
    window.localStorage.setItem(CART_KEY, encriptCart(data));
}

export const getCartList = () => {
    let cartList = window.localStorage.getItem(CART_KEY);
    cartList = cartList ? decriptCart(cartList) : [];

    const isValid =  cartValidade(cartList);

    return  isValid ? (cartList.list ? cartList.list : []) : [];

}

export const addCartItem = (_data) => {
    const list = getCartList();
    list.push(_data);
    saveCart(list);
}

export const removeCartItem = (_data) => {
    let list = getCartList();
    list = list.filter(item => ~~item.id !== ~~_data);
    saveCart(list);

}

export const clearCart = () => {
    window.localStorage.removeItem(CART_KEY);
}