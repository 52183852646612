import React, {useState, useEffect, useRef, useCallback} from 'react';

import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TabMenu } from 'primereact/tabmenu';
import { Divider } from 'primereact/divider';
import { Carousel } from 'primereact/carousel';

import { CardProduct } from '../../components/card/product';

import { ImageLoader } from '../../components/imageloader';
import { home } from 'react-icons-kit/icomoon/home';
import { Icon } from 'react-icons-kit';
import {facebook2} from 'react-icons-kit/icomoon/facebook2';
import {instagram} from 'react-icons-kit/fa/instagram';

import location from '../../assets/image/local.png';
import open from '../../assets/image/estabelecimento.png';
import delivery from '../../assets/image/taxa_entrega.png';
import money from '../../assets/image/pedido_minimo.png';

import { useAPP } from '../../services/provider/app';

import { getStore, getStoreCategories, getStoreProducts } from '../../services/api/store';

import { formatToMoney } from '../../services/utils';

import { URL_APLICATION } from '../../services/api';

import '../../assets/style/pages/store/index.scss';

export const PageStore = (props) => {

    const [store, setStore] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [category, setCategory] = useState(0);
    const [productsList, setProcuctsList] = useState([]);
    const [productsListPromotional, setProcuctsListPromotional] = useState([]);

    const [isOpenList, setIsOpenList] = useState([]);
    const [isOpenStatus, setIsOpenStatus] = useState('');

    const cover = useRef();
    const isOpen = useRef();
    const sectionArea = useRef();

    const {company, setCompany} = useAPP();


    const getAddress = () => {
        if(!store)
            return '';
        
        return `${store?.detalhe?.endereco}, ${store?.detalhe?.numero} - ${store?.detalhe?.bairro}`;
    }

    const getProductsByCategory = (_category) => {
        return (productsList || []).filter(item => ~~item.categoria === ~~_category && (/n/gi).test(item.promocional));
    }

    const getDataStore = useCallback(()=>{

        const getData = async () => {
            const data = await getStore(props.match.params.client);
            console.log(data.data);
            setStore(data.data);
            setCompany(data.data);
        }

        getData();

    },[props, setCompany]);

    const getDataStoreProducts = useCallback(()=>{

        const getData = async () => {
            
            const productsData = await getStoreProducts(props.match.params.client);
            setProcuctsList(productsData.data);
            //console.log(productsData.data);

            setProcuctsListPromotional(productsData.data.filter(item => (/s/gi).test(item.promocional)));
            
            const categoriesData = await getStoreCategories(props.match.params.client);
            const categoriesTemp = categoriesData.data.map((item, id) => ({id: id, category_id: ~~item.id, label: item.categoria}));
            setCategoryList(categoriesTemp);
            setCategory(categoriesTemp[0].id);
        }

        getData();

    },[props]);

    const getSocialMedia = () => {

        const _facebook = store?.detalhe?.facebook ? <Icon size={'1.5em'} onClick={()=> window.open(`https://www.facebook.com/${store?.detalhe?.facebook}`)} icon={facebook2} /> : '';
        const _instagram = store?.detalhe?.instagram ? <Icon size={'1.5em'} onClick={()=> window.open(`https://www.instagram.com/${store?.detalhe?.instagram}`)} icon={instagram} /> : '';
        
        return <>
            {_facebook}
            {_instagram}
        </>;
    }

    const getListOpen = useCallback(() => {
        const week_names = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
        const list =  week_names.map(item => {
            const data = store.detalhe.funcionamento.filter(week => (new RegExp(week.dia_semana,'gi')).test(item));
            return {
                weekName: item, 
                start: data.length > 0 ? data[0].inicio : '00:00:00',
                end: data.length > 0 ? data[0].fim : '00:00:00',
            }
        });

        setIsOpenList(<div style={{width:'250px', fontSize:'0.8em'}}>
            {list.map((item, id) => {
                return <div key={`time-open-${id}`} className="p-grid">
                    <div className="p-col-6">
                        <strong>{item.weekName}</strong>:
                    </div>
                    <div className="p-col-6">
                        {item.start} ás {item.end}
                    </div>
                </div>
            })}
        </div>)

    },[store]);

    const getStatusOpen = useCallback(() => {
        const week_names = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
        const list =  week_names.map(item => {
            const data = store.detalhe.funcionamento.filter(week => (new RegExp(week.dia_semana,'gi')).test(item));
            return {
                weekName: item, 
                start: data.length > 0 ? data[0].inicio : '00:00:00',
                end: data.length > 0 ? data[0].fim : '00:00:00',
            }
        });

        const current = new Date();
        const data_current = list[current.getDay()];

        let _status;

        if( (/00:00:00/gi).test(data_current.start.toString()) && (/00:00:00/gi).test(data_current.end.toString())){
            _status = 'Fechado';
        }else{
            const [ih, im, is] = data_current.start.split(':');
            const start = (~~ih * 3600) + (~~im * 60) + (~~is * 1000);

            const [eh, em, es] = data_current.end.split(':');
            const end = (~~eh * 3600) + (~~em * 60) + (~~es * 1000);

            const temp = (~~current.getHours() * 3600) + (~~current.getMinutes() * 60) + (~~current.getSeconds() * 1000);

            if(temp >= start && temp <= end){
                _status = 'Aberto';
            }else if(end <= start && (temp <= start && temp <= end)){
                _status = 'Aberto';
            }else{
                _status = 'Fechado';
            }    
        }
        setIsOpenStatus(_status);


    },[store]);

    const executeScroll = (_category) => {
        const _categoryActive = sectionArea.current.querySelector(`#category_${_category}`);

        sectionArea.current.parentNode.scrollTop = Math.abs(_categoryActive.offsetParent.offsetTop) - _categoryActive.getBoundingClientRect().height - 115;
    }

    const scrollController = useCallback(() => {

        sectionArea.current.parentNode.addEventListener('scroll', () => {

            if(sectionArea.current){

                if(sectionArea.current.querySelector("#menutab").getBoundingClientRect().y <= 115)
                    sectionArea.current.querySelector("#menutab").classList.add('fixed');
                else
                    sectionArea.current.querySelector("#menutab").classList.remove('fixed');

                const list = sectionArea.current.querySelectorAll('[id*="category_"]');

                let current = null;

                Array.prototype.map.call(list, item => {

                    if(item.getBoundingClientRect().y <= (sectionArea.current.querySelector("#menutab").querySelector('.p-tabmenu').getBoundingClientRect().y + 150))
                        current = item.getAttribute('id');

                    return item;

                });

                if(current){
                    const item = categoryList.filter(item => ~~item.category_id === ~~current.replace(/\D/gi,''))
                    setCategory(~~item[0]?.id);
                }

            }

            
        })

    }, [categoryList]);

    useEffect(()=>{
        if(!company){
            getDataStore();
        }else{
            setStore(company);
        }

        getDataStoreProducts();

    },[getDataStore, getDataStoreProducts, company]);

    useEffect(()=>{

        if(store?.detalhe?.capa)
            cover?.current?.setAttribute('style', `background-image:url(${URL_APLICATION}${store.detalhe.capa})` );   

        setAvatar(store?.detalhe?.icone? `${URL_APLICATION}${store?.detalhe?.icone}`: null);

        if(store){
            scrollController();
            getListOpen();
            getStatusOpen();
        }

    },[store, scrollController, getListOpen, getStatusOpen]);

    return (
        <div ref={sectionArea} className="page-store">
            <header>
                <div ref={cover} className="cover">

                </div>
                <div className="client-info">
                    <div className="data-client">
                        <div className="avatar">
                            <ImageLoader image={avatar} default={home} />
                        </div>
                        <div className="text p-m-2">
                            <strong>
                                {store?.nome}
                            </strong>
                            <div style={{fontSize:'0.8em'}}>
                                {store?.detalhe?.celular || store?.detalhe?.telefone}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="area-info">
                <div className="box">
                    <div className="icon">
                        <div className="icon-image">
                            <img src={location} alt="" />
                        </div>
                    </div>
                    <div className="text">
                        {store ? getAddress() : <ProgressSpinner   style={{width: '36px', height: '36px'}} />}
                    </div>
                </div>

                <div className="box">
                    <div className="icon">
                        <div className="icon-image">
                            <img src={open} alt="" />
                        </div>
                    </div>
                    <div className="text">
                        <Button style={{fontSize:'1em'}} onClick={(e)=> isOpen.current.toggle(e)} className="p-button-text p-button-plain" label={`Estabelecimento: ${isOpenStatus}`} icon="pi pi-arrow-down" iconPos="right" />
                        <OverlayPanel ref={isOpen}>
                            {isOpenList}
                        </OverlayPanel>
                    </div>
                </div>

                <div className="box">
                    <div className="icon">
                        <div className="icon-image">
                            <img src={delivery} alt="" />
                        </div>
                    </div>
                    <div className="text">
                        {store ? <><strong>Taxa de entrega</strong>: {formatToMoney(store?.detalhe?.taxaentrega)}</> : <ProgressSpinner   style={{width: '36px', height: '36px'}} />}
                       
                    </div>
                </div>

                <div className="box">
                    <div className="icon">
                        <div className="icon-image">
                            <img src={money} alt="" />
                        </div>
                    </div>
                    <div className="text">
                        {store ? <><strong>Pedido mínimo</strong>: {formatToMoney(store?.detalhe?.pedidominimo)}</> : <ProgressSpinner   style={{width: '36px', height: '36px'}} />}
                        
                    </div>
                </div>
            </div>
            <nav id="menutab">
                <TabMenu id="menutab_itens" activeIndex={category} onTabChange={(e)=> { setCategory(e.value.id); executeScroll(e.value.category_id) } } model={categoryList} />
            </nav>
            <section>
                {
                    categoryList.length > 0 ? 
                        <div>
                            {
                                categoryList.map((cat, cat_id) => {
                                    return <div key={`group-category-${cat_id}`} >
                                            <Divider className="p-mb-5 p-mt-5">
                                                <strong id={`category_${cat.category_id}`}>{cat.label}</strong>
                                            </Divider>
                                            <div className="area-products">
                                                {
                                                    getProductsByCategory(cat.category_id).map((product, product_id) => {
                                                        return <CardProduct key={`card-product-${product_id}`} {...product} {...props} />
                                                    })
                                                }
                                            </div>
                                        </div>
                                })
                            }
                        </div>
                    :
                       <div style={{textAlign: 'center', height:'200px'}}> <ProgressSpinner style={{width: '36px', height: '36px', margin: '10px auto'}} /> </div>
                }
            </section>
            { categoryList.length > 0 ? 
                <div className="area-promotion p-mt-4 p-mb-4">
                    <Divider align="left">
                        <div className="p-d-inline-flex p-ai-center">
                            <i style={{color:'var(--yellow-500)'}} className="pi pi-star p-mr-2"></i>
                            <b>Itens promocionais</b>
                        </div>
                    </Divider>
                    <Carousel responsiveOptions={
                        [
                            {
                                breakpoint: '8024px',
                                numVisible: 3,
                                numScroll: 3
                            },
                            {
                                breakpoint: '1024px',
                                numVisible: 3,
                                numScroll: 3
                            },
                            {
                                breakpoint: '768px',
                                numVisible: 2,
                                numScroll: 2
                            },
                            {
                                breakpoint: '560px',
                                numVisible: 1,
                                numScroll: 1
                            }
                        ]
                    }
                    value={productsListPromotional} itemTemplate={(item)=>{
                            return <CardProduct key={`card-promotion-${item.id}`} type="promotional" {...item} />
                    }}></Carousel>

                </div>
            :
            ''
            }
            <footer>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <div className="company p-mb-1">{store ? <strong>{store?.nome}</strong> : <ProgressSpinner   style={{width: '36px', height: '36px'}} />}</div>
                        <div className="slogan">{store ? store?.detalhe?.slogan : <ProgressSpinner   style={{width: '36px', height: '36px'}} />}</div>
                        <div className="social-media">
                            {store ? getSocialMedia() : <ProgressSpinner   style={{width: '36px', height: '36px'}} />}
                        </div>
                    </div>
                    <div className="p-col-12 p-md-6">
                        {
                            store ?
                            <>
                                {
                                    store?.detalhe?.latitude && store?.detalhe?.longitude ?
                                        <iframe className="p-m-b-2" width="100%" height="auto" id="gmap_canvas" title="Localização" src={`https://maps.google.com/maps?q=${store?.detalhe?.latitude},${store?.detalhe?.longitude}&z=16&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                                    :
                                    ''
                                }
                            </>
                            :
                                <ProgressSpinner   style={{width: '36px', height: '36px'}} />
                        }
                    </div>
                </div>
            </footer>
        </div>
    )
}