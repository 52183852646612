import { URL_API } from "..";

export const getStore = async (_store) => {

    if(!_store)
        return null;

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/${_store}`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const getStoreCategories = async (_store) => {

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/${_store}/categories`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const getStoreProducts = async (_store) => {

    const header = new Headers();

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}companys/${_store}/products`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}