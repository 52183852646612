import React, { useState, useRef } from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { getStore } from '../../services/api/store';

import '../../assets/style/pages/home/index.scss'; 

import { pwaServices } from '../../services/pwa';

export const PageHome = (props) => {

    const [isLoad, setIsLoad] = useState(false);
    const [store, setStore] = useState('');

    const toast = useRef(null);

    const searchStore = async () => {
        setIsLoad(true);

        const data = await getStore(store);

        setIsLoad(false);

        if(data.data){
            props.history.push(`/${store}`);
        }else{
            toast.current.show({severity: 'error', summary: 'Erro', detail: 'Empresa não encontrada.'});
        }
    }

    pwaServices('pedidos_web');

    return (
        <div className="page-home">
            <div className="main-frame">
                <div className="p-grid p-m-3">
                    <div className="p-col-12" style={{textAlign:'center'}}>
                        <strong>Pedidos Web</strong>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <InputText value={store} onChange={(e)=> setStore(e.target.value)}  placeholder="Informe o código da empresa"/>
                            <Button loading={isLoad} onClick={()=>searchStore()} icon="pi pi-search" className="p-button-warning" />
                        </div>
                    </div>
                </div>
            </div>
            <Toast ref={toast} position="bottom-center" />
        </div>
    )
}