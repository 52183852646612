import {URL_API} from '../';
import { getToken } from '../../auth';

export const userLogin = async (_store, _data) => {

    const header = new Headers();

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/${_store}/login`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userSigup = async (_data) => {

    const header = new Headers();

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/create`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userProfile = async () => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}users/profile`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userUpdate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/update`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userAddress = async () => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}users/address`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userAddressCreate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/create/address`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userAddressUpdate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/update/address`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userAddressDelete = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/delete/address`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userPayment = async () => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}users/cards`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userPaymentCreate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/create/card`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userPaymentUpdate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'PUT',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/update/card`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userPaymentDelete = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'DELETE',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/delete/card`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userRequests = async () => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'GET',
        headers: header
    };

    const request = await fetch(`${URL_API}users/requests`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userRequestCreate = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/request/create`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}

export const userRequestCreateitem = async (_data) => {

    const token = getToken();

    const header = new Headers();
    header.append('Authorization', `Bearer ${token}`);

    const params = {
        method: 'POST',
        headers: header,
        body: JSON.stringify(_data)
    };

    const request = await fetch(`${URL_API}users/request/create/item`, params);
    let json = {};

    try{
        json = await request.json();
    }catch(e){
        json.data = null;
    }

    return json;

}