import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { PageBase } from '../../../pages/base';
import { PageHome } from '../../../pages/home';

export const MainRouter = (props) => {
    return(
        <BrowserRouter basename='/'>
            <Switch>
                <Route path="/:client" component={(p)=> <PageBase {...props} {...p} />} />
                <Route path="*" component={(p)=> <PageHome {...props} {...p} />} />
            </Switch>
        </BrowserRouter>
    )
}