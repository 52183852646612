import React,{useState, useEffect} from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';

import { Icon } from 'react-icons-kit';

export const ImageLoader = (props) => {

    const [image, setImage] = useState(null);

    useEffect(()=>{
        
        if(props.image){
            const image = new Image();
            image.alt = "";
            image.src = props.image;
            image.addEventListener('load', ()=>{
                setImage(<img style={{maxWidth:'100%'}} src={props.image} alt="" />);
            });
            image.addEventListener('error', ()=> {
                setImage(<Icon style={{color:'#aaa'}} size="5rem" icon={props.default} />);
            })
        }


    },[props]);

    return (
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', textAlign:'center', ...props.style}} className="image-loader">
            {
                image ?
                    image
                :
                    <ProgressSpinner/>
            }
        </div>
    )
}