import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Divider } from 'primereact/divider';
import { Toast } from 'primereact/toast';

import { getCartList, removeCartItem, clearCart } from '../../services/shoppingcart';
import { formatToMoney } from '../../services/utils';

import { userAddress, userPayment, userRequestCreate, userRequestCreateitem } from '../../services/api/user';

import { useAPP } from '../../services/provider/app';

import { isAuthenticated } from '../../services/auth';

import '../../assets/style/pages/cart/index.scss';

export const PageCartList = (props) => {


    const [isLoad, setIsLoad] = useState(false);
    const [deliverylist, setDeliverylist] = useState();
    const [delivery, setDelivery] = useState();
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [paymentlist, setPaymentlist] = useState();
    const [payment, setPayment] = useState();
    
    const {setVersion, company} = useAPP();

    const toast = useRef(null);
    
    console.log(company);

    const getTotal = () => {
        const items = getCartList();
        
        const value = items.reduce((_c, _n) => {

            let itens = (_n.combo || []);
            itens = itens.filter(el => ~~el.quantity > 0);
            const valueItens = itens.reduce((_a, _i)=> {
                return _a + (parseFloat(_i.price) * ~~_i.quantity)
            }, 0);

            

            return _c + ((parseFloat(_n.price) * ~~_n.quantity) + (valueItens * ~~_n.quantity)  - (parseFloat(_n.descont) * ~~_n.quantity));
        } , 0);

        return value;
    }

    const getTotalItem = (_data) => {
        const items = _data;

        let itens = (items.combo || []);
            itens = itens.filter(el => ~~el.quantity > 0);
            const valueItens = itens.reduce((_a, _i)=> {
                return _a + (parseFloat(_i.price) * ~~_i.quantity)
            }, 0);
        
        return ((parseFloat(items.price) * ~~items.quantity) + (valueItens * ~~items.quantity)  - (parseFloat(items.descont) * ~~items.quantity));
    }

    const getDeliveryData = useCallback(() => {

        const getData = async () => {
            let list = [
                {label: 'Entregar na mesa', value:'mesa', price: 0},
                {label: 'Retirar pedido', value:'retirar', price: 0}
            ];

            const address =  await userAddress();

            address?.data?.map(item=> {
                list.push({label:` ${item.endereco}, ${item.numero}${item.complemento ? `- ${item.complemento}`: ''} - ${item.bairro} - ${item.cidade}`, value:~~item.id, price: company?.detalhe?.taxaentrega});
                return item;
            })

            setDeliverylist(list);

        }

        getData();

    }, [company])

    const getPaymentData = useCallback(() => {

        const getData = async () => {
            let list = [
                {label:'Dinheiro', value:5 },
                {label:'Débito', value:1 },
            ];

            const paymentdata =  await userPayment();

            paymentdata?.data?.map(item=> {
                list.push({label:`Crédito - ${item.portador} - ${item.numerotruncado}- ${item.val_mes}/${item.val_ano}`, value:~~item.id, portador: item.portador});
                return item;
            })

            setPaymentlist(list);

        }

        getData();

    }, [])

    const getValueDelivery = useCallback(() => {
        const d = deliverylist?.filter(item => item.value === delivery)[0];
        setDeliveryPrice(d ? d.price : 0);
    },[delivery, deliverylist])

    const closeRequest = async () => {

        setIsLoad(true);

        const date = new Date();
        const uniqueId = `${props.match.params.client}${date.getDay()}${date.getMonth()}${date.getFullYear()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;
        const card =  paymentlist.filter(item => ~~item.value === ~~payment)[0];
        console.log(payment, card);
        const saveData = {
            unique: uniqueId,
            empresa: props.match.params.client,
            esc_responsaveis_enderecos_id: ~~delivery,
            cartao: 1,
            alterado: 'APP',
            tipo: 16,
            titular: card?.portador,
            total: getTotal() + parseFloat(deliveryPrice)

        }

        console.log(saveData);

        await userRequestCreate(saveData);

        const listItens = [];

        getCartList().map(item => {

            const saveDataItem = {
                pedido: uniqueId,
                cartao: 1,
                empresa: props.match.params.client,
                tipo: 0,
                produto: item.id,
                descricao: item.description,
                preco: item.price,
                qtde: item.quantity,
                total: item.quantity * item.price,
                forma_pagamento: payment
            }

            listItens.push(userRequestCreateitem(saveDataItem));

            item?.combo?.map(subitem => {

                const saveDataSubItem = {
                    pedido: uniqueId,
                    cartao: 1,
                    empresa: props.match.params.client,
                    tipo: 0,
                    produto: subitem.id,
                    descricao: subitem.description,
                    preco: subitem.price,
                    qtde: subitem.quantity,
                    total: subitem.quantity * subitem.price
                }

                listItens.push(userRequestCreateitem(saveDataSubItem));

                return subitem;
            })


            return item;
        })

        await Promise.all(listItens);

        clearCart();

        toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Pedido criado com sucesso.', onCallback:()=>props.history.push(`/${props.match.params.client}/user/requests`)});

        setVersion(new Date().getTime());

        setIsLoad(false);

    }

    const verifyComplete = (e) => {
        if(e?.onCallback){
            e.onCallback();
        }
    }

    useEffect(()=>{

        getValueDelivery();

    }, [delivery, getValueDelivery])

    useEffect(()=>{

        getDeliveryData();
        getPaymentData();

    }, [getDeliveryData, getPaymentData]);

    return (
        <div className="page-cart-list">
            <div className="main-frame">
                <h3>
                    Carrinho de compras
                </h3>
                <div className="p-d-flex p-mb-4 p-mt-2 p-jc-end">
                    <Button onClick={()=>props.history.push(`/${props.match.params.client}`)} className="p-button-text" icon="pi pi-plus" label="Adicionar novo item" />
                </div>
                <div className="p-grid">
                    <div className="p-col-12">
                    {
                            getCartList().length > 0 ?
                                <div key={`toolbar-rigth-cart-`}>
                                    <Divider >
                                        <strong>Itens adicionados</strong>
                                    </Divider>
                                    <div style={{minWidth:'500px', width:'100%', maxHeight:'50vh', overflowY:'auto', overflowX:'hidden', fontSize:'0.8em'}}>
                                        {
                                            getCartList().map((item, id) => {
                                                return <div key={`cart-list-${id}`} className="p-grid p-m-1 p-ai-center" >
                                                    <div className="p-col-fixed" style={{width:'40px', textAlign:'center'}}>
                                                        <span style={{borderRadius: '50%', width:'22px', height:'22px', minWidth:'22px', minHeight:'22px', backgroundColor:'var(--green-100)', display:'flex', alignItems:'center', justifyContent:'center', fontSize:'0.7em'}}>
                                                            {id + 1}
                                                        </span>
                                                    </div>
                                                    <div className="p-col">
                                                        <div>{item.quantity} X {item.name}</div>
                                                        <div style={{margin:'5px 0', fontSize:'0.8em'}}>
                                                            {item.description}
                                                        </div>
                                                    </div>
                                                    <div className="p-col-fixed" style={{width:'90px', textAlign:'right'}}>
                                                        {formatToMoney(getTotalItem(item))}
                                                    </div>
                                                    <div className="p-col-fixed" style={{width:'40px'}}>
                                                        <Button onClick={(e) => { removeCartItem(item.id); setVersion(new Date().getTime())}} icon="pi pi-trash" className="p-button-text p-button-danger p-button-rounded" />
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <Divider />
                                    {
                                        isAuthenticated() ?
                                            <>
                                                <h3>
                                                    Local da entrega
                                                </h3>
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-9">
                                                        <Dropdown options={deliverylist} value={delivery} onChange={(e)=> setDelivery(e.value) } placeholder="Selecione o local de entrega" style={{width:'100%'}} />
                                                    </div>
                                                    <div className="p-col-12 p-md-3" style={{textAlign:'right'}}>
                                                        <Button label="Adicionar endereço" icon="pi pi-plus" className="p-button-text p-button-sm" />
                                                    </div>
                                                </div>
                                                <h3>
                                                    Forma de pagamento
                                                </h3>
                                                <div className="p-grid">
                                                    <div className="p-col-12 p-md-9">
                                                        <Dropdown options={paymentlist} value={payment} onChange={(e)=> setPayment(e.value) } placeholder="Selecione a forma de pagamento" style={{width:'100%'}} />
                                                    </div>
                                                    <div className="p-col-12 p-md-3" style={{textAlign:'right'}}>
                                                        <Button label="Adicionar pagamento" icon="pi pi-plus" className="p-button-text p-button-sm" />
                                                    </div>
                                                </div>
                                                <Divider />
                                            </>
                                        :
                                            ''
                                    }
                                    <div className="p-gridp-m-2 p-jc-end" style={{textAlign:'right'}}>
                                        <div style={{margin:'5px 0', fontSize:'0.8em'}}><strong>Sub total</strong>: {formatToMoney(getTotal())}</div>
                                        <div style={{margin:'5px 0', fontSize:'0.8em'}}><strong>Frete</strong>: {formatToMoney(deliveryPrice)}</div>
                                        <div><strong>Total</strong>: {formatToMoney(getTotal() + parseFloat(deliveryPrice))}</div>
                                    </div>
            
                                    <div className="p-d-flex p-m-2 p-jc-center">
                                    {
                                        isAuthenticated() ?
                                            <Button loading={isLoad} onClick={()=> closeRequest() } label="Finalizar" className="p-button-danger p-button-sm" />
                                        :
                                            <>
                                                <Button onClick={(e) => props.history.push(`/${props.match.params.client}/user/sigup`)} style={{width:'100px'}} label="Cadastar" className="p-button p-button-sm p-mr-2" />
                                                <Button onClick={(e) => props.history.push(`/${props.match.params.client}/user/login`)} style={{width:'100px'}} label="Login" className="p-button-secondary p-button-sm" />
                                            </>
                                    }
                                    </div>
                                </div>
                            :
                            <p>Não há itens no carrinho.</p>
                        }
                    </div>
                </div>
            </div>
            <Toast ref={toast} position="bottom-center" onHide={(e)=> verifyComplete(e)} onRemove={(e)=> verifyComplete(e)} />
        </div>
    )
}