import React, {useState, useRef} from 'react';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';

import { userAddressCreate } from '../../../../services/api/user';

import { useForm, Controller } from 'react-hook-form';

import '../../../../assets/style/pages/user/address/create/index.scss';

export const PageUserAddressCreate = (props) => {

    const defaultValues = {
        cep:'',
        endereco:'',
        numero:'',
        complemento:'',
        bairro:'',
        cidade:'',
        uf:'',
        referencia:''
    }

    const [isLoad, setIsLoad] = useState(false);
    const [zipcodeLoad, setZipcodeLoad] = useState(false);

    const form = useForm({ defaultValues });
    const control =  form.control;
    const errors  = form.formState.errors ;
    const handleSubmit = form.handleSubmit;

    const toast = useRef(null);

    const getDataAddress = async (_data) => {

        if((_data || '' ).replace(/\D/gi,'').length === 8){

            setZipcodeLoad(true);

            const url_base = `https://viacep.com.br/ws/${_data.replace(/\D/gi,'')}/json/`;

            const header = new Headers();
            header.append('Access-Control-Allow-Origin', '*');
            header.append('Access-Control-Allow-Headers','*');
            
            const params = {
                method: 'GET',
                headers: header
            }

            const addressData = await fetch(url_base, params);
            const addressLoad = await addressData.json();

            form.setValue('endereco', addressLoad.logradouro);
            form.setValue('bairro', addressLoad.bairro);
            form.setValue('cidade', addressLoad.localidade);
            form.setValue('uf', addressLoad.uf);

            setZipcodeLoad(false);

        }
    }

    const verifyComplete = (e) => {
        if(e?.onCallback){
            e.onCallback();
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onSubmit = async (_data) => {
        setIsLoad(true);

        delete _data.uf;

        const data = await userAddressCreate(_data);

        setIsLoad(false);

        if(data.data){
            toast.current.show({severity: 'success', summary: 'Sucesso', detail: 'Endereço cadastrado.', onCallback:()=>props.history.push(`/${props.match.params.client}/user/address`)});
        }else{
            toast.current.show({severity: 'error', summary: 'Erro', detail: 'Endereço não cadastrado. Tente novamente.'});
        }
    }

    return (
        <div className="page-user-address-create">
            <div className="main-frame">
            <form onSubmit={handleSubmit(onSubmit)}>
                    <h3>
                        Cadastro de endereço
                    </h3>
                    <div className="p-grid p-mt-4">
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label p-input-icon-right" style={{width:'100%'}}>
                                    <Controller name="cep" control={control} render={({ field, fieldState }) => (
                                        <>
                                            {zipcodeLoad ? <i className="pi pi-spin pi-spinner" /> : '' }
                                            <InputText onInput={(e) => getDataAddress(e.target.value)} style={{width:'100%'}} id={field.name} {...field} />
                                        </>
                                    )} />
                                    <label htmlFor="cep">Cep</label>
                                </span>
                                {getFormErrorMessage('cep')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="endereco" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="endereco">Endereço</label>
                                </span>
                                {getFormErrorMessage('endereco')}
                            </div>
                        </div>
                    </div>

                    <div className="p-grid p-mt-2">
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="numero" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="numero">Número</label>
                                </span>
                                {getFormErrorMessage('numero')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="complemento" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="complemento">Complemento</label>
                                </span>
                                {getFormErrorMessage('complemento')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="bairro" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="bairro">Bairro</label>
                                </span>
                                {getFormErrorMessage('barro')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="cidade" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="cidade">Cidade</label>
                                </span>
                                {getFormErrorMessage('cidade')}
                            </div>
                        </div>
                        <div className="p-col-12 p-md-2">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="uf" control={control} render={({ field, fieldState }) => (
                                        <InputText style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="uf">Estado</label>
                                </span>
                                {getFormErrorMessage('uf')}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid p-mt-2">
                        <div className="p-col-12">
                            <div className="p-field">
                                <span className="p-float-label">
                                    <Controller name="referencia" control={control} render={({ field, fieldState }) => (
                                        <InputTextarea autoResize={true} style={{width:'100%'}} id={field.name} {...field} />
                                    )} />
                                    <label htmlFor="referencia">Ponto de Referencia</label>
                                </span>
                                {getFormErrorMessage('referencia')}
                            </div>
                        </div>
                    </div>
                    <div className="p-d-flex p-mt-2 p-jc-end">
                        <Button onClick={()=> props.history.push(`/${props.match.params.client}/user/address`)} label="Cancelar" className="p-button-danger p-mr-2" style={{width:'100px'}} />
                        <Button type="submit" loading={isLoad} label="Cadastrar" className="p-button" style={{width:'100px'}} />
                    </div>
                </form>
            </div>
            <Toast ref={toast} position="bottom-center" onHide={(e)=> verifyComplete(e)} onRemove={(e)=> verifyComplete(e)} />
        </div>
    )
}